import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SsoComponent } from './sso.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
  declarations: [SsoComponent],
  imports: [CommonModule, SharedModule],
  exports: [SsoComponent],
})
export class SsoModule {}
